<template>
	<div class="breadcrumb-wrapper" v-if="breadcrumbs.length > 0" >
		<div class="wrap">

			<el-breadcrumb  separator-class="el-icon-arrow-right" class="menu-wrap">
				<el-breadcrumb-item  v-for="(item, index) in pages" :key="index" :to="item.linkUrl" >
					{{item.name}}
				</el-breadcrumb-item>
				<!-- <el-breadcrumb-item v-if="index < breadcrumbs.length - 1" class="separator">{{separator}}</el-breadcrumb-item> -->
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			pages: {
				type: Array,
				default :function(){
					return []
				}
			}
		},
		data() {
			return {
				breadcrumbs: []
			}
		},
		created() {
			this.breadcrumbs=this.pages
			// if (typeof this.breadcrumbs === 'string') {
			// 	this.breadcrumbs = [{
			// 		name: this.breadcrumbs
			// 	}];
			// }
			// this.breadcrumbs.push({
			// 	name: '首页',
			// 	linkUrl: '/pages/index'
			// });
		},
		methods: {
			goPage(url) {
				if (url) {
					this.$router.push({ path: url})
				}
			}
		}
	}
</script>

<style lang="scss">
	.breadcrumb-wrapper {
		// background-color: $bg-color-grey;
		
		.wrap {
			width: $page-width;
			margin: 0 auto;
			font-size: 12px;
			.breadcrumb {
				color: #757575;
				line-height: 40px;

				&.enabled {
					cursor: pointer;

					&:hover {
						color: #424242;
					}
				}
			}

			.separator {
				margin: 0 .5em;
				color: #b0b0b0;
				line-height: 40px;
			}
		}
	}
</style>
